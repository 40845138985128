@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
    font-family: "cat";
    src: url("/public/Whocats.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  
.text-cat {
    font-family: 'cat';
}
body{
 font-family: 'cat';
}