.bg-grad {
   background: rgb(37, 8, 56);
   background: linear-gradient(
      180deg,
      rgb(22, 56, 8) 29%,
      rgba(1, 15, 31, 1) 58%
   );
}
.bg-con-footer {
   background: url("../public/divider.png") no-repeat;
   background-size: cover;
}
